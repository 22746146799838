import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { FaCircle, FaInfo } from "react-icons/fa";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useHttpRequest } from "../../../Hooks";
import { COLORS } from "../../../Styles/colors";
import { useAppSelector } from "../../../Redux/app/hooks";

export const MassExecutionForm = ({ setOpenModal }) => {
  const customer = useAppSelector((state) => state.customer);
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isFormProcessing, setFormProcessing] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [threshold_set, setThresholdSet] = useState(true);
  const [statusMsg, setStatusMsg] = useState("");
  const [remainingCampaigns, setRemainingCampaigns] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [remainingOpen, setRemainingOpen] = React.useState(true);

  const [formData, setFormData] = useState({
    name: "",
    execMethod: "exe",
    piMethod: null,
    piTarget: null,
    sleep: 2,
    maxRuntime: 30,
    transform: "",
    architecture: "x64",
    simulation: "",
    relay: false,
    inMemory: false,
    obfuscate: false,
    changeName: false,
    campaign_level_vars: [],
    schedule: "now",
    start_time: null,
    end_time: null,
    sla_time: 30
  });

  const [simulation, setSimulation] = useState(null);

  // ----- VARIABLES -----

  const execMethod = [
    {
      value: "exe",
      name: "Binary Executable",
    },
    {
      value: "dll",
      name: "Signed Proxy Execution",
    },
    {
      value: "sh",
      name: "Remote Process Injection",
    },
    {
      value: "local",
      name: "Local Process Injection",
    },
  ];

  const architectures = [
    {
      value: "x64",
      name: "64-bit",
    },
    {
      value: "x32",
      name: "32-bit",
    },
  ];

  const linuxExecMethod = [
    {
      value: "exe",
      name: "Executable",
    },
  ];

  const piMethods = [
    "CreateRemoteThread",
    "NtCreateRemoteThread",
    "QueueUserAPC",
    "NtQueueUserAPC",
    "RtlCreateUserThread",
  ];

  const { response: piTargets } = useHttpRequest({
    method: "GET",
    path: "/api/v2/process-injection-targets",
  });

  const { response: transformMethods } = useHttpRequest({
    method: "GET",
    path: "/api/v2/admin/payload-transforms",
  });

  const { response: simulations } = useHttpRequest({
    method: "GET",
    path: "/api/v2/simulations",
    params: { alertValidation: true },
  });

  const {
    response: massExecutionCheck,
    fetch: submit,
    status: meStatus,
  } = useHttpRequest({
    method: "POST",
    path: "/api/v2/mass-executions",
    data: formData,
    defer: true,
  });

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitDisabled(true);
    setShowErrorMsg(false);
    setFormProcessing(true);
    submit();
  };

  useEffect(() => {
    if (meStatus === null) return;
    if (meStatus.status === 200) {
      setFormProcessing(false);
      setOpenModal(false);
    } else {
      setFormProcessing(false);
      setSubmitDisabled(false);
      setShowErrorMsg(true);
    }
  }, [meStatus]);


  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getLists = async () => {
      if (
        formData.campaign_level_vars.length === 0 &&
        simulation.campaign_level_vars
      ) {
        setFormData({
          ...formData,
          campaign_level_vars: simulation.campaign_level_var_defaults,
        });
      }
      const get_sim_plan = await getRequest(
        `/react/api/${customer.uuid}/simulations/simplan/${simulation.uuid}`,
        accessToken
      );
      if (get_sim_plan.status === 200) {
        setThresholdSet(get_sim_plan.data.simplan.threshold_set);
      }
      setLoaded(true);
    };

    if (customer && simulation) {
      getLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, simulation, customer]);

  return (
    <Paper>
      {!loaded ? (
        <Box className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </Box>
      ) : (
        <Stack sx={{ p: 3 }}>
          <Typography variant="h5">Create New Mass Execution</Typography>
          <Divider style={{ margin: "1rem 0" }} />
          {!threshold_set && (
            <Alert sx={{ marginBottom: 1 }} severity="error">
              Thresholds are not set on this Simulation. This may affect
              scoring!
            </Alert>
          )}

          <Stack spacing={2}>
            {/* Name */}
            <TextField
              required
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              label="Name"
              size="small"
            />

            {/* Simulations */}
            <FormControl>
              <InputLabel required>Simulation</InputLabel>
              <Select
                value={simulation ? simulation : ""}
                onChange={(e) => {
                  setFormData({ ...formData, simulation: e.target.value.id });
                  setSimulation(e.target.value);
                }}
                label="Simulation"
                size="small"
              >
                {simulations &&
                  simulations.map((sim) => {
                    return (
                      <MenuItem key={sim.id} value={sim}>
                        {sim.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            {/* Platform */}
            <TextField
              value={simulation?.platform || ""}
              label="Platform"
              disabled
              size="small"
            />

            {/* Campaign Level Variables */}
            {simulation?.campaign_level_vars && (
              <>
                {simulation.campaign_level_vars.map((variable, index) => (
                  <Stack key={index} direction="row">
                    <Tooltip
                      title={simulation.campaign_level_var_descriptions[index]}
                    >
                      <TextField
                        label={variable}
                        value={formData.campaign_level_vars[index]}
                        required={true}
                        onChange={(e) => {
                          const updatedVariables = [
                            ...formData.campaign_level_vars,
                          ];
                          updatedVariables[index] = e.target.value;
                          setFormData({
                            ...formData,
                            campaign_level_vars: updatedVariables,
                          });
                        }}
                      />
                    </Tooltip>
                  </Stack>
                ))}
              </>
            )}

            {/* Architecture */}
            <FormControl>
              <InputLabel required>Architecture</InputLabel>
              <Select
                value={formData.architecture ? formData.architecture : ""}
                onChange={(e) => {
                  setFormData({ ...formData, architecture: e.target.value });
                }}
                label="Architecture"
                size="small"
              >
                {architectures.map((arch) => {
                  return (
                    <MenuItem key={arch.value} value={arch.value}>
                      {arch.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* Execution Method */}
            <FormControl>
              <InputLabel required>Execution Method</InputLabel>
              <Select
                size="small"
                value={formData.execMethod ? formData.execMethod : ""}
                onChange={(e) => {
                  setFormData({ ...formData, execMethod: e.target.value });
                  switch (e.target.value) {
                    case "local":
                      setFormData({
                        ...formData,
                        execMethod: e.target.value,
                        piMethod: "",
                        piTarget: "",
                        inMemory: false,
                      });
                      break;
                    case "sh":
                      setFormData({
                        ...formData,
                        execMethod: e.target.value,
                        transform: "",
                        inMemory: false,
                      });
                      break;
                    default:
                      setFormData({
                        ...formData,
                        execMethod: e.target.value,
                        piMethod: "",
                        piTarget: "",
                        transform: "",
                        inMemory: false,
                      });
                      break;
                  }
                }}
                label="Exectution Method"
              >
                {simulation?.platform === "linux"
                  ? linuxExecMethod.map((exec) => {
                    return (
                      <MenuItem key={exec.value} value={exec.value}>
                        {exec.name}
                      </MenuItem>
                    );
                  })
                  : execMethod.map((exec) => {
                    return (
                      <MenuItem key={exec.value} value={exec.value}>
                        {exec.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            {/* PI stuff */}
            {/* PI method */}
            {formData.execMethod.includes("sh") && (
              <>
                <FormControl>
                  <InputLabel required>Process Injection Method</InputLabel>
                  <Select
                    value={formData.piMethod ? formData.piMethod : ""}
                    onChange={(e) =>
                      setFormData({ ...formData, piMethod: e.target.value })
                    }
                    label="Process Injection Method"
                    size="small"
                  >
                    {piMethods &&
                      piMethods.map((exec) => {
                        return (
                          <MenuItem value={exec} key={exec}>
                            {exec}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel required>Process Injection Target</InputLabel>
                  <Select
                    value={formData.piTarget || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, piTarget: e.target.value })
                    }
                    label="Process Injection Target"
                    size="small"
                  >
                    {piTargets
                      ? piTargets.map((exec) => {
                        return (
                          <MenuItem key={exec.uuid} value={exec.uuid}>
                            {exec.name}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </>
            )}
            {formData.execMethod.includes("local") && (
              <FormControl>
                <InputLabel required>Transform</InputLabel>
                <Select
                  value={formData.transform ? formData.transform : ""}
                  onChange={(e) =>
                    setFormData({ ...formData, transform: e.target.value })
                  }
                  label="Transform"
                  size="small"
                >
                  {transformMethods &&
                    transformMethods.map((exec, index) => {
                      return (
                        <MenuItem value={index + 1} key={index}>
                          {exec.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  minWidth: "30%",
                }}
              >
                <DateTimePicker
                  label="Start Date & Time"
                  value={formData.start_time}
                  onChange={(newValue) => {
                    setFormData({ ...formData, start_time: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDateTime={dayjs()}
                />
                <DateTimePicker
                  label="End Date & Time"
                  value={formData.end_time}
                  onChange={(newValue) => {
                    setFormData({ ...formData, end_time: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDateTime={formData.start_time}
                />
              </Box>
            </LocalizationProvider>

            <TextField
              type={"number"}
              value={formData.sleep}
              label="Sleep Time"
              size="small"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  sleep:
                    e.target.value <= 0
                      ? 0
                      : parseInt(e.target.value.toString()),
                });
              }}
            />

            <FormControl>
              <InputLabel required>Max Runtime</InputLabel>
              <Select
                value={formData.maxRuntime ? formData.maxRuntime : ""}
                onChange={(e) =>
                  setFormData({ ...formData, maxRuntime: e.target.value })
                }
                label="Max Runtime"
                size="small"
              >
                <MenuItem value={0}>Unlimited</MenuItem>
                {[...Array(15)].map((x, i) =>
                  i !== 0 ? (
                    <MenuItem key={i * 15} value={i * 15}>
                      {i * 15} minutes
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </FormControl>

            <TextField
              type={"number"}
              value={formData.sla_time}
              label="SLA Time (in minutes)"
              size="small"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  sla_time:
                    e.target.value <= 0
                      ? 0
                      : parseInt(e.target.value.toString()),
                });
              }}
            />

            <FormGroup>
              {!formData.execMethod.includes("sh") &&
                !formData.execMethod.includes("dll") && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.inMemory}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            inMemory: e.target.checked,
                          })
                        }
                      />
                    }
                    label="inMemory"
                  />
                )}
              <Tooltip title="Instead of embedding files within the payload, have the files be downloaded from the agent. Note: only for the Beta Agent">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.relay}
                      onChange={(e) => {
                        setFormData({ ...formData, relay: e.target.checked });
                      }}
                    />
                  }
                  label={
                    <Stack direction="row">
                      <FaInfo
                        style={{
                          color: COLORS.info.main,
                          marginRight: "0.5rem",
                        }}
                      />
                      File Relay (! BETA !)
                    </Stack>
                  }
                />
              </Tooltip>

              <Tooltip title="Obfuscate the contents of the payload to make it harder to detect. Note: This will make the payload take longer to compile than normal">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.obfuscate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          obfuscate: e.target.checked,
                        })
                      }
                    />
                  }
                  label={
                    <Stack direction="row">
                      <FaInfo
                        style={{
                          color: COLORS.info.main,
                          marginRight: "0.5rem",
                        }}
                      />
                      Obfuscate Payload
                    </Stack>
                  }
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.changeName}
                    onChange={(e) =>
                      setFormData({ ...formData, changeName: e.target.checked })
                    }
                  />
                }
                label="Randomize payload name"
              />
            </FormGroup>

            <Typography
              sx={{ display: showErrorMsg ? "block" : "none" }}
              color="error"
              variant="p"
            >
              Bad Request: {statusMsg}
            </Typography>
            <LoadingButton
              variant="contained"
              loading={isFormProcessing}
              disabled={
                submitDisabled || simulation === null || formData.name === ""
              }
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};
