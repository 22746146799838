import {
  Breadcrumbs,
  Typography,
  Stack,
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { FaEllipsisV, FaFileAlt } from "react-icons/fa";
import { useState, useContext, useEffect } from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import { MEOverviewContext } from "./Provider/Context";
import { Stats } from "./Stats";
import { useHttpRequest } from "../../../Hooks";
import { toast } from "react-toastify";
import { fileRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";

export const MEHeader = ({}) => {
  const role = useAppSelector((state) => state.user.role);
  const customer = useAppSelector((state) => state.customer);
  const { accessToken } = useContext(DataContext);
  const { massExecution } = useContext(MEOverviewContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadCSV = async () => {
    setDownloading(true);
    const loadingToast = toast.loading("Generating CSV");
    const res = await fileRequest(
      `/api/v2/${customer.uuid}/mass-executions/${massExecution.id}/csv`,
      accessToken,
      `${massExecution.name}.csv`
    );
    setDownloading(false);
    toast.dismiss(loadingToast);
    if (res.status === 200) {
      toast.success("CSV generated successfully");
    } else {
      toast.error("Something went wrong");
    }
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Breadcrumbs>
            <Typography color="textPrimary">Alert Validation</Typography>
            <Typography color="textPrimary">Mass Executions</Typography>
            <Typography color="textPrimary">{massExecution.name}</Typography>
          </Breadcrumbs>
          <IconButton
            role={role}
            aria-controls="download-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <FaEllipsisV />
          </IconButton>
        </Stack>
        <Typography variant="h4">{massExecution.name}</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(0);
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleDownloadCSV();
          }}
          disabled={role === "View_Only" || downloading}
        >
          <ListItemIcon>
            <FaFileAlt
              style={{
                marginRight: "10px",
                opacity: role === "View_Only" ? 0.5 : 1,
              }}
            />
          </ListItemIcon>
          Download CSV
        </MenuItem>
      </Menu>
    </Stack>
  );
};
