import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Link,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaBroom, FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TechniqueAddForm from "../../../Components/Forms/Reporting/TechniqueAddForm/TechniqueAddForm";
import { DataContext } from "../../../Context/dataContext";
import { postRequest } from "../../../Helpers/httpRequests";
import { COLORS } from "../../../Styles/colors";
import CampaignContextInfo from "../ReportsCampaignDetails/Components/CampaignContextInfo";
import CampaignInfo from "../ReportsCampaignDetails/Components/CampaignInfo";
import CampaignDetailsProvider from "../ReportsCampaignDetails/provider/CampaignDetailsProvider";
import ScoreButtonGroup from "./ScoreButtonGroup";
import BSBreadcrumbs from "./components/BSBreadcrumbs";
import PrevNext from "./components/PrevNext";
import { useAppSelector } from "../../../Redux/app/hooks";
const ReportsFindingsDetails = (props) => {
  // ----- STATES && CONTEXT -----

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const [reloadPage, setReloadPage] = useState(true);
  const [addTechniqueModal, setAddTechniqueModal] = useState(false);
  const [finding, setFinding] = useState({});
  const [techs, setTechs] = useState([]);
  const [finding_uuid, setFindingUUID] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [alertEvidence, setAlertEvidence] = useState([]);
  const [logEvidence, setLogEvidence] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [note, setNote] = useState("");
  const [bsuser, setbsuser] = useState("");
  const [noteUUID, setNoteUUID] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [linesShown, setLinesShown] = useState("10");
  const [expectedOutcome, setExpectedOutcome] = useState(null);
  const [failedBtnStates, setFailedBtnStates] = useState({
    title: "Mark as Failed",
    color: "error",
  });
  const [securityTools, setSecurityTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState("");

  // ----- VARIABLES -----
  const noteModalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "40%",
    maxWidth: 1000,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "70%",
    height: "100%",
    maxWidth: 900,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const role = useAppSelector((state) => state.user.role);
  const { executedID, cachedID } = useParams();

  // ----- FUNCTIONS -----

  const handleEvidence = async (evidence) => {
    // Convert JSON object to string
    const jsonStr = JSON.stringify(evidence);

    // Create a Blob from the JSON string
    const blob = new Blob([jsonStr], { type: "application/json" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  const handleToolChange = async (value) => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/tools/${executedID}/${cachedID}/${value}`,
      accessToken,
      {
        executeID: executedID,
        itemID: cachedID,
        toolID: value,
      }
    );
    if (res.status === 200) {
      toast.success("Security Tool Changed Successfully");
      setTimeout(() => {
        toast.dismiss();
      }, 1000);
    }
  };
  /**
   * Function that takes the cached finding UUID and sends it to backend to be marked as failed.
   */
  const markAsFailed = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/report/finding/flip/${cachedID}`,
        accessToken
      ),
      {
        success: `Successfully marked as ${
          failedBtnStates.color === "success" ? "Executed" : "Failed"
        }`,
        pending: `Marking as ${
          failedBtnStates.color === "success" ? "Executed" : "Failed"
        }..`,
        error: `Failed to mark as ${
          failedBtnStates.color === "success" ? "Executed" : "Failed"
        }..`,
      }
    );
    if (!res.data.msg) {
      setFailedBtnStates({ title: "Mark as Failed", color: "error" });
    } else {
      setFailedBtnStates({ title: "Mark as Executed", color: "success" });
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
  };

  const deleteNote = async (params) => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/campaign/DeleteNote/${params}`,
      accessToken
    );
    if (res.status === 200) {
      setReloadPage((current) => !current);
    }
  };

  const editNote = async (params) => {
    setNote(params.note);
    setNoteUUID(params.uuid);
    setOpenEditModal(true);
  };

  const handleSubmit = async () => {
    const data = {
      note: note,
      cachedID: cachedID,
    };
    const result = await postRequest(
      `/react/api/${current_customer.uuid}/report/campaign/addNote/${executedID}`,
      accessToken,
      data
    );
    if (result.status === 200) {
      setOpenModal(false);
      setReloadPage((current) => !current);
    } else {
      setShowErrorMsg(true);
      setStatusMsg(result.data.message);
    }
  };

  const EditNoteSubmit = async () => {
    const data = {
      note: note,
    };
    const result = await postRequest(
      `/react/api/${current_customer.uuid}/report/campaign/EditNote/${noteUUID}`,
      accessToken,
      data
    );
    if (result.status === 200) {
      setOpenEditModal(false);
      setReloadPage((current) => !current);
    } else {
      setShowErrorMsg(true);
      setStatusMsg(result.data.message);
    }
  };

  const unLink = async (tech) => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/findings/${finding.uuid}/${tech}/remove`,
      accessToken
    );
    if (res.status === 200) {
      setReloadPage((current) => !current);
    }
  };

  const closeTechniqueModal = () => {
    setAddTechniqueModal(false);
  };

  const handleLinesShownChange = (event) => {
    setLinesShown(event.target.value);
  };

  /**
   * handles the logic responsible for setting the expectedOutcome and background color of the
   * scoring button group.
   */
  const configureOutcome = (finding) => {
    const sim_action = finding.simulation_action;
    if (sim_action.blocked) setExpectedOutcome("Blocked");
    if (sim_action.alerted) setExpectedOutcome("Alerted");
    if (sim_action.logged) setExpectedOutcome("Logged");
    if (sim_action.nothing) setExpectedOutcome("No Evidence");
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getData = async () => {
      const url = `/react/api/${current_customer.uuid}/report/finding/${executedID}/${cachedID}`;

      const data = {
        rows: linesShown,
      };

      const res = await postRequest(url, accessToken, data);
      if (res.status === 200) {
        setFinding(res.data.finding);
        setAlertEvidence(JSON.parse(res.data.finding.alert_evidence));
        setLogEvidence(JSON.parse(res.data.finding.log_evidence));
        setTechs(res.data.finding.attack_id);
        setFindingUUID(res.data.finding.uuid);
        setbsuser(res.data.bsuser);
        if (res.data.finding.failed) {
          setFailedBtnStates({ title: "Mark as Executed", color: "success" });
        } else {
          setFailedBtnStates({ title: "Mark as Failed", color: "error" });
        }
        if (res.data.finding.simulation_action) {
          configureOutcome(res.data.finding);
        }
        setSelectedTool(res.data.finding?.security_tool?.id || 0);
        setSecurityTools([
          {
            id: 0,
            name: "No Change",
            uuid: "unchanged",
          },
          {
            id: 1,
            name: "None",
            uuid: "none",
          },
          ...res.data.securityTools,
        ]);
      }
    };

    getData();
  }, [
    current_customer,
    accessToken,
    reloadPage,
    executedID,
    cachedID,
    linesShown,
  ]);

  return (
    <>
      <BSBreadcrumbs campaignUUID={executedID} />

      <PrevNext campaignUUID={executedID} findingUUID={cachedID} />
      <Box
        sx={{
          marginTop: "10px",
          marginBottom: 2,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography fontWeight={"bold"} variant="h4">
          Details for{" "}
          {finding && finding.name && finding.name.substring(0, 100)}{" "}
          {finding.simulation_action && finding.simulation_action.clean_up && (
            <Tooltip title="This is a clean up step">
              <IconButton>
                <FaBroom size={30} />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      </Box>
      {finding.response === "Action did not Run" && (
        <Alert onClose severity="error">
          Action did not Execute - Agent Terminated Before Execution
        </Alert>
      )}

      {finding.step === -1 ? (
        <CampaignDetailsProvider executedUUID={executedID}>
          <Box
            className="left-box"
            sx={{ flex: 1, paddingRight: 1, marginTop: 2 }}
          >
            <CampaignInfo />
            <CampaignContextInfo />
          </Box>
        </CampaignDetailsProvider>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box
            className="left-box"
            sx={{ flex: 1, paddingRight: 1, marginTop: 1, width: "50%" }}
          >
            <Paper sx={{ marginBottom: 2, padding: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{
                          fontWeight: "bold",
                          fontSize: 20,
                          borderBottom: "None",
                        }}
                      >
                        Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "none", fontWeight: "bold" }}
                      >
                        Title
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {finding &&
                          finding.name &&
                          finding.name.substring(0, 53)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "none", fontWeight: "bold" }}
                      >
                        Time of Execution
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {moment(finding.timestamp).format(
                          "MMM D, YYYY hh:mm a"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "none", fontWeight: "bold" }}
                      >
                        Process ID
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {finding.process || "None"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "none", fontWeight: "bold" }}
                      >
                        Execution Method
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {finding &&
                        finding.campaign &&
                        finding.campaign.payload &&
                        finding.campaign.payload.execution
                          ? finding.campaign.payload.execution +
                            "-" +
                            finding.campaign.payload.platform
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "none", fontWeight: "bold" }}
                      >
                        Expected Outcome
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {expectedOutcome}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "none", fontWeight: "bold" }}
                      >
                        Last Modified
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        [{finding.last_modified_time}] -{" "}
                        {finding.last_modified_by}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper sx={{ marginBottom: 2, padding: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{ fontWeight: "bold", fontSize: 20 }}
                      >
                        Attack Techniques in this Action
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Attack Id</TableCell>
                      <TableCell>Platform</TableCell>
                      <TableCell>Data Sources</TableCell>
                      <TableCell>Phases</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {techs.map((tech, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {tech.attack_id} - {tech.name.substring(0, 40)}
                        </TableCell>
                        <TableCell>
                          {tech.x_mitre_platforms.map((obj, index) => (
                            <div key={index}>{obj.name}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {tech.x_mitre_data_sources.map((obj, index) => (
                            <div key={index}>{obj.name},</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {tech.tactic.map((obj, index) => (
                            <div key={index}>{obj.name},</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          <Button
                            disabled={role === "View_Only"}
                            style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                            variant="contained"
                            sx={{ width: "100%" }}
                            onClick={() => unLink(tech.uuid)}
                          >
                            Unlink {tech.attack_id}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                disabled={role === "View_Only"}
                style={{ opacity: role === "View_Only" ? 0.7 : 1 }}
                sx={{ marginTop: 2, width: "100%" }}
                variant="contained"
                onClick={() => {
                  setAddTechniqueModal(true);
                }}
              >
                Edit All Attack Techniques in this Action
              </Button>
            </Paper>
            {finding.alerted === true && alertEvidence && (
              <Paper sx={{ marginBottom: 2, padding: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{
                            fontWeight: "bold",
                            fontSize: 20,
                            borderBottom: "none",
                          }}
                        >
                          Alert Evidence
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ borderBottom: "none", fontWeight: "bold" }}
                        >
                          Title
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "none", fontWeight: "bold" }}
                        >
                          Severity
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "none", fontWeight: "bold" }}
                        >
                          Alert URL
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "none", fontWeight: "bold" }}
                        >
                          Alert Evidence(s)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {alertEvidence.map((alert, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {alert.title}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {alert.severity}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            <Link
                              href={alert.alertWebUrl}
                              target="_blank"
                              rel="noopener"
                              underline="none"
                            >
                              View Alert
                            </Link>
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {alert.evidence.map((evidence, idx) => (
                              <Link
                                key={idx}
                                href="#"
                                underline="none"
                                onClick={() => handleEvidence(evidence)}
                              >
                                Evidence {idx + 1}
                              </Link>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            {finding.logged === true && logEvidence && (
              <Paper sx={{ marginBottom: 2, padding: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{
                            fontWeight: "bold",
                            fontSize: 20,
                            borderBottom: "none",
                          }}
                        >
                          Log Evidence
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Link
                            href="#"
                            underline="none"
                            onClick={() => handleEvidence(logEvidence)}
                          >
                            Evidence
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Box>

          <Stack
            spacing={2}
            className="right-box"
            sx={{ flex: 1, paddingLeft: 3, marginTop: 1, width: "50%" }}
          >
            <Paper sx={{ padding: 2 }}>
              <Stack spacing={1}>
                <Typography component={"span"} fontWeight={"bold"} variant="h6">
                  Score
                </Typography>
                {Object.keys(finding).length > 0 && (
                  <ScoreButtonGroup
                    finding={finding}
                    customer={current_customer.uuid}
                    setFinding={setFinding}
                    setReloadPage={setReloadPage}
                  />
                )}

                <Button
                  fullWidth
                  variant="outlined"
                  color={failedBtnStates.color}
                  onClick={markAsFailed}
                >
                  {failedBtnStates.title}
                </Button>

                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="security-tool-label">
                    Security Tool
                  </InputLabel>
                  <Select
                    label="Security Tool"
                    disabled={role === "View_Only"}
                    sx={{
                      opacity: role === "View_Only" ? 0.5 : 1,
                      height: 40,
                    }}
                    labelId="security-tool-select"
                    value={selectedTool || ""}
                    onChange={(e) => {
                      setSelectedTool(e.target.value);
                      handleToolChange(e.target.value);
                    }}
                  >
                    {securityTools &&
                      securityTools.map((tool) => {
                        return (
                          <MenuItem key={tool.uuid} value={tool.id}>
                            {tool.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Stack>
            </Paper>
            <Paper sx={{ padding: 2 }}>
              <Typography
                component={"span"}
                fontWeight={"bold"}
                variant="h6"
                sx={{
                  marginTop: 4,
                }}
              >
                Action
              </Typography>
              <Typography
                sx={{ color: "red", overflowY: "scroll", marginBottom: 1 }}
              >
                {finding.request || finding.name}
              </Typography>
              {finding.simulation_action && finding.simulation_action.note && (
                <div>
                  <Divider sx={{ my: 2 }} />
                  <Typography
                    component={"span"}
                    variant="subtitle1"
                    sx={{
                      marginTop: 2,
                      color: "gray",
                      fontStyle: "italic",
                    }}
                  >
                    Note
                  </Typography>
                  <Typography sx={{ fontStyle: "italic" }}>
                    {finding.simulation_action.note}
                  </Typography>
                </div>
              )}
            </Paper>

            <Paper sx={{ padding: 2, overflow: "scroll" }}>
              <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    component={"span"}
                    fontWeight={"bold"}
                    variant="h6"
                  >
                    Response
                  </Typography>
                  <Box sx={{ minWidth: 100 }}>
                    <FormControl fullWidth>
                      <InputLabel>Lines shown</InputLabel>
                      <Select
                        size="small"
                        value={linesShown}
                        label="Age"
                        onChange={handleLinesShownChange}
                      >
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"ALL"}>All</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
                <pre style={{ color: "red", whiteSpace: "pre-wrap" }}>
                  {finding.response}
                </pre>
              </Stack>
              {finding.substeps && (
                <Stack>
                  <Typography
                    component={"span"}
                    fontWeight={"bold"}
                    variant="h6"
                  >
                    Substeps
                  </Typography>
                  <Box sx={{ minWidth: 100 }}>
                    {finding.substeps.map((step) => {
                      // steps are b64 encoded, decode them into objects
                      const substepStr = atob(step);
                      const substep = JSON.parse(substepStr);
                      const time = new Date();
                      time.setTime(substep.timestamp * 1000);
                      return (
                        <Accordion key={substep.step_number}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Substep {substep.step_number} - {substep.command}
                          </AccordionSummary>
                          <AccordionDetails>
                            [{time.toUTCString()}]{" "}
                            {!substep.success &&
                              "Substep marked as failure with message: "}{" "}
                            {substep.output}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                </Stack>
              )}
            </Paper>
            <Paper sx={{ padding: 2 }}>
              <Typography
                component={"span"}
                fontWeight={"bold"}
                variant="h6"
                sx={{
                  marginTop: 4,
                }}
              >
                Notes
              </Typography>
              {/* Notes */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Note
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", marginLeft: 4 }}
                >
                  Author
                </Typography>
                <IconButton
                  disabled={role === "View_Only"}
                  style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  title="Add Note"
                  onClick={() => setOpenModal(true)}
                >
                  <FaPlus color={COLORS.primary.main} />
                </IconButton>
              </Box>
              {finding &&
                finding.campaign &&
                finding.campaign.notes &&
                finding.campaign.notes.length === null && (
                  <Typography>No Notes</Typography>
                )}
              {finding &&
                finding.campaign &&
                finding.campaign.notes &&
                finding.campaign.notes.length > 0 &&
                finding.campaign.notes.map((note, index) => {
                  if (note.finding_uuid === cachedID) {
                    return (
                      <div key={index}>
                        <Stack
                          sx={{ justifyContent: "space-between" }}
                          direction="row"
                          spacing={3}
                        >
                          <Typography
                            sx={{ alignSelf: "center", width: "20rem" }}
                          >
                            {note.note}
                          </Typography>
                          <Typography>{note.author}</Typography>
                          {note.author === bsuser && (
                            <Stack direction="row" spacing={3}>
                              <IconButton
                                onClick={() => editNote(note)}
                                title="Edit Note"
                              >
                                <FaPencilAlt />
                              </IconButton>
                              <IconButton
                                sx={{ alignSelf: "right" }}
                                onClick={() => deleteNote(note.uuid)}
                                title="Delete Note"
                              >
                                <FaTrashAlt color={COLORS.error.main} />
                              </IconButton>
                            </Stack>
                          )}
                        </Stack>
                        {finding &&
                          finding.campaign &&
                          finding.campaign.notes &&
                          finding.campaign.notes.length > 1 && <Divider />}
                      </div>
                    );
                  }
                  return <React.Fragment key={index}></React.Fragment>;
                })}
            </Paper>
            <Paper sx={{ padding: 2 }}>
              <Typography
                component={"span"}
                fontWeight={"bold"}
                variant="h6"
                sx={{
                  marginTop: 4,
                }}
              >
                Mitigations
              </Typography>
              <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
                {techs.map((tech, index) => {
                  return (
                    <Typography component={"span"} key={index}>
                      <Typography component={"span"} fontWeight={"bold"}>
                        {tech.attack_id}
                        <span> - </span>
                        {tech.name}
                        <br></br>
                        <Typography component={"span"}>
                          {tech.mitigations}
                        </Typography>
                        <br></br>
                      </Typography>
                    </Typography>
                  );
                })}
              </Typography>
            </Paper>
            <Paper sx={{ padding: 2 }}>
              <Typography
                component={"span"}
                fontWeight={"bold"}
                variant="h6"
                sx={{
                  marginTop: 4,
                }}
              >
                Possible Detections
              </Typography>
              <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
                {techs.map((tech, index) => {
                  return (
                    <Typography component={"span"} key={index}>
                      <Typography component={"span"} fontWeight={"bold"}>
                        {tech.attack_id}
                        <span> - </span>
                        {tech.name}
                        <br></br>
                        <Typography component={"span"}>
                          {tech.detection}
                        </Typography>
                        <br></br>
                      </Typography>
                    </Typography>
                  );
                })}
              </Typography>
            </Paper>
            <Paper sx={{ padding: 2 }}>
              <Typography
                component={"span"}
                fontWeight={"bold"}
                variant="h6"
                sx={{
                  marginTop: 4,
                }}
              >
                References
              </Typography>
              <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
                {techs.map((tech, index) => {
                  return (
                    <Typography component={"span"} key={index}>
                      <Typography component={"span"} fontWeight={"bold"}>
                        {tech.attack_id}
                        <span> - </span>
                        {tech.name}
                        <br></br>
                        {tech.external_references.map((ef, index) => {
                          return (
                            <Typography
                              className="text-links"
                              component={"span"}
                              key={index}
                            >
                              {ef.url}
                              <br></br>
                            </Typography>
                          );
                        })}
                      </Typography>
                    </Typography>
                  );
                })}
              </Typography>
            </Paper>
          </Stack>
        </Box>
      )}
      {/* Add Technique Modal */}
      <Modal
        open={addTechniqueModal}
        onClose={closeTechniqueModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <TechniqueAddForm
            finding_uuid={finding_uuid}
            setModalOpen={setAddTechniqueModal}
            onFinish={() => setReloadPage((current) => !current)}
          />
        </Box>
      </Modal>

      {/* New Note modal */}
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={noteModalStyle} component="form">
          <Typography variant="h5">Add New Note</Typography>
          <Stack direction="column" spacing={2}>
            {/* Note */}
            <TextField
              type="text"
              label="Note"
              helperText=""
              minRows={1}
              maxRows={4}
              multiline
              onChange={(e) => setNote(e.target.value)}
            />

            {/* Error Status Message */}
            <Typography
              sx={{ display: showErrorMsg ? "block" : "none" }}
              color="error"
              variant="p"
            >
              Bad Request: {statusMsg}
            </Typography>
            <LoadingButton variant="contained" onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      {/* Edit Note modal */}
      <Modal
        open={openEditModal}
        onClose={closeEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={noteModalStyle} component="form">
          <Typography variant="h5">Edit Note</Typography>
          <Stack direction="column" spacing={2}>
            {/* Note */}
            <TextField
              type="text"
              value={note}
              label="Note"
              helperText=""
              minRows={1}
              maxRows={4}
              multiline
              onChange={(e) => setNote(e.target.value)}
            />

            {/* Error Status Message */}
            <Typography
              sx={{ display: showErrorMsg ? "block" : "none" }}
              color="error"
              variant="p"
            >
              Bad Request: {statusMsg}
            </Typography>
            <LoadingButton variant="contained" onClick={EditNoteSubmit}>
              Submit
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ReportsFindingsDetails;
