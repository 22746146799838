import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import { useHttpRequest } from "../../../Hooks";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridWithStyles, TextButton } from "../../../Components";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { MassExecutionForm } from "./MassExecutionForm";
import { Link } from "react-router-dom";
import { COLORS } from "../../../Styles/colors";
import { deleteRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import { toast } from "react-toastify";

const utcToLocal = (utcDate) => {
  const date = new Date(utcDate);
  // format the date to be more readable
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

const MassExecutions = () => {
  const { accessToken } = useContext(DataContext);
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const current_customer = useAppSelector((state) => state.customer);
  const role = useAppSelector((state) => state.user.role);

  const { response, loading } = useHttpRequest({
    path: "/api/v2/mass-executions",
    method: "GET",
  });

  const populateDataGrid = (data) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => {
          return (
            <Link
              to={`/${current_customer.uuid}/alertvalidation/mass-executions/${params.row.id}`}
            >
              <Button>{params.value}</Button>
            </Link>
          );
        },
      },
      {
        field: "start_date",
        headerName: "Start Date",
        flex: 1,
      },
      {
        field: "end_date",
        headerName: "End Date",
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
      },
      {
        field: "cid",
        headerName: "CID",
        flex: 1,
      },
      {
        field: "num_endpoints",
        headerName: "Number of Endpoints",
        flex: 1,
      },
      {
        field: "execution_type",
        headerName: "Execution Type",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => {
          const handleDelete = async () => {
            const res = await deleteRequest(`/${current_customer.uuid}/alertvalidation/mass-executions/${params.row.id}`, accessToken)
            if (res.status === 200) {
              toast.success("Mass Execution delted")
            }

            return
          }
          return (

            <Tooltip title="Delete Mass Execution">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete()
                }}
              >
                <FaTrashAlt color={COLORS.error.light} />
              </IconButton>
            </Tooltip>
          )
        }
      },
    ];
    const rows = data.map((row) => {
      console.log(row)
      return {
        id: row.id,
        name: row.name,
        start_date: utcToLocal(row.start_time),
        end_date: utcToLocal(row.end_time),
        status: row.status,
        cid: row.cid,
        num_endpoints: row.num_endpoints,
        execution_type: row.execution_type,
      };
    });
    setDataTableColumns(columns);
    setDataTableRows(rows);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,

  };

  useEffect(() => {
    if (response) {
      populateDataGrid(response);
    }
  }, [response]);

  return current_customer && !loading ? (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Typography color="text.primary">Mass Executions</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">Mass Executions</Typography>
        <TextButton
          role={role}
          tooltip="Schedule Mass Execution"
          icon={FaPlus}
          onClick={() => {
            setOpenModal(true);
          }}
        />
      </Box>

      {dataTableRows.length === 0 ? (
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          No mass executions found
        </Alert>
      ) : (
        <Stack spacing={2}>
          <DataGridWithStyles
            name="mass-executions"
            columns={dataTableColumns}
            rows={dataTableRows}
            paper
            autoHeight
            disableRowSelectionOnClick
          />
        </Stack>
      )}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box style={style}>
          <MassExecutionForm setOpenModal={setOpenModal} />
        </Box>
      </Modal>
    </Stack>
  ) : (
    <CircularProgress />
  );
};

export default MassExecutions;
