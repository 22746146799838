import React from "react";
import AVLibrary from "../../Pages/AlertValidation/AVLibrary";
import AVHistory from "../../Pages/AlertValidation/AVHistory";
import AVDetails from "../../Pages/AlertValidation/AVDetails";
import AVConfig from "../../Pages/AlertValidation/AVConfig";
import AVLibDetails from "../../Pages/AlertValidation/AVLibDetails/AVLibDetails";
import { Routes, Route } from "react-router-dom";
import ServiceManager from "../../Pages/AlertValidation/ServiceManager/ServiceManager";
import AVSettings from "../../Pages/AlertValidation/AVSettings/AVSettings";
import AVOverview from "../../Pages/AlertValidation/AVOverview/AVOverview";
import ExerciseTemplates from "../../Pages/AlertValidation/ExerciseTemplates";
import ScheduledExercises from "../../Pages/AlertValidation/ScheduledExercises";
import MassExecutions from "../../Pages/AlertValidation/MassExecutions";
import MassExecutionOverview from "../../Pages/AlertValidation/MassExecutionOverview";

const AlertValidationRoutes = () => {
  return (
    <Routes>
      {/* these have /alervalidation/ before it */}
      <Route exact path="library" element={<AVLibrary />} />
      <Route exact path="library/:avUUID" element={<AVLibDetails />} />
      <Route
        exact
        path="scheduled-exercises"
        element={<ScheduledExercises />}
      />
      <Route exact path="alerts/history/:alertUUID" element={<AVHistory />} />
      <Route
        exact
        path="alerts/details/:campaignUUID"
        element={<AVDetails />}
      />
      <Route exact path="config" element={<AVConfig />} />
      <Route path="service-manager" element={<ServiceManager />} />
      <Route path="settings" element={<AVSettings />} />
      <Route path="overview" element={<AVOverview />} />
      <Route path="templates/exercise" element={<ExerciseTemplates />} />
      <Route path="mass-executions" element={<MassExecutions />} />
      <Route path="mass-executions/:id" element={<MassExecutionOverview />} />
    </Routes>
  );
};

export default AlertValidationRoutes;
