import { useState, useEffect, useContext } from "react";
import { Paper, Box, Stack, Typography, Tabs, Tab } from "@mui/material";
import { DataGridWithStyles } from "../../../Components";
import { MEOverviewContext } from "./Provider/Context";
import { useAppSelector } from "../../../Redux/app/hooks";
import { encryptId } from "../../../Helpers/functions/uni_funcs";
import { Link } from "react-router-dom";

export const EndpointsTable = () => {
  const customer = useAppSelector((state) => state.customer);

  const { massExecution } = useContext(MEOverviewContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [allRows, setAllRows] = useState([]);

  useEffect(() => {
    if (massExecution.alert_campaigns) {
      console.log(massExecution.alert_campaigns);
      setAllRows(
        massExecution.alert_campaigns.map((row) => ({
          id: row.id,
          uuid: row.uuid,
          name: row.machinename__name,
          status: row.alert_validation_status,
          alert_id: row.alert_id,
        }))
      );
    }
  }, [massExecution]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        const link = `/${
          customer.uuid
        }/alertvalidation/alerts/details/${encryptId(params.row.id)}`;
        return (
          <Stack direction="row" marginLeft={1}>
            <Link to={link}>{params.row.name}</Link>
          </Stack>
        );
      },
    },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  useEffect(() => {
    if (selectedTab === 1) {
      setRowsToShow(allRows.filter((row) => row.status === "failed"));
    } else if (selectedTab === 2) {
      setRowsToShow(allRows.filter((row) => row.status === "passed"));
    } else {
      setRowsToShow(allRows);
    }
  }, [selectedTab, allRows]);

  return (
    <Paper sx={{ p: 2 }} variant="outlined">
      <Stack spacing={2}>
        <Typography variant="h6">Endpoints</Typography>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          variant="fullWidth"
        >
          <Tab label="all" />
          <Tab label="failed" />
          <Tab label="passed" />
        </Tabs>
        <DataGridWithStyles
          columns={columns}
          rows={rowsToShow}
          pageSize={10}
          autoHeight
        />
      </Stack>
    </Paper>
  );
};
