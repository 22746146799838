import React, { useContext, useEffect, useState } from "react";
import { useHttpRequest } from "../../../Hooks";
import {
  Button,
  Typography,
  Stack,
  Box,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { postRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../Redux/app/hooks";

export const PaddedFileModule = ({ actionData, setActionData }) => {
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);

  // this might look confusing
  // formData here is for the specific call to get the padkey
  const [formData, setFormData] = useState({
    file: { name: "" },
    existingFile: "",
    args: "",
    mode: "",
  });

  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const filetype = "." + file.name.split(".").reverse()[0];
    const temp = actionData;
    temp.arguments[1] = file.name;
    setActionData(temp);
    if (filetype === ".bin") {
      setFormData({ ...formData, file: file, mode: ".bin", existingFile: "" });
    } else if (filetype === ".exe") {
      setFormData({ ...formData, file: file, mode: ".exe", existingFile: "" });
    } else {
      setFormData({ ...formData, file: file, mode: "other", existingFile: "" });
    }
  };

  const handleExistingFileSelect = (event) => {
    setFormData({
      ...formData,
      file: { name: "" },
      existingFile: event.target.value,
    });
    const selectedFile = fileOptions.find((f) => f.id === event.target.value);
    const temp = actionData;
    if (selectedFile) {
      const parts = selectedFile.exe_file.split("/");
      const name = parts[parts.length - 1];
      temp.arguments[1] = name;
    } else {
      temp.arguments = "(";
    }
    setActionData(temp);
  };

  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    if (formData.args === "") {
      const temp = actionData;
      temp.arguments[3] = "";
      setActionData(temp);
    }

    const res = await postRequest(
      `/react/api/${customer.uuid}/simulations/pad-file`,
      accessToken,
      formData,
      false
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    if (res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      const temp = actionData;
      temp.arguments[5] = res.data.pad1;
      temp.arguments[7] = res.data.pad2;
      setActionData(temp);
    } else {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror PaddedFileModule.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  const { response: fileOptions } = useHttpRequest({
    method: "GET",
    path: "/api/v2/execution-files",
  });

  useEffect(() => {
    const preFilename = actionData.arguments[1];
    fileOptions?.forEach((element) => {
      const parts = element.exe_file.split("/");
      const name = parts[parts.length - 1];
      if (name === preFilename) {
        setFormData({
          ...formData,
          existingFile: element.id,
          args: actionData.arguments[3],
        });
      }
    });
    // eslint-disable-next-line
  }, [fileOptions, actionData]);
  return (
    <Stack spacing={3}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              verticalAlign: "middle",
            }}
          >
            <TextField
              value={formData.file.name}
              label={"Upload Executable"}
              required
              InputProps={{
                fullWidth: true,
                readOnly: true,
                startAdornment: (
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    component="label"
                    sx={{ mr: 1 }}
                  >
                    {"CHOOSE FILE"}
                    <input type="file" onChange={handleFileSelect} hidden />
                  </Button>
                ),
              }}
            />

            <Typography component={"h5"}>OR</Typography>

            <FormControl>
              <InputLabel>Existing File</InputLabel>
              <Select
                value={formData.existingFile || ""}
                onChange={handleExistingFileSelect}
              >
                <MenuItem value="">None</MenuItem>
                {fileOptions?.map((f) => {
                  const parts = f.exe_file.split("/");
                  const name = parts[parts.length - 1];
                  return <MenuItem value={f.id}>{name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>

          <TextField
            value={formData.args}
            onChange={(e) => {
              setFormData({ ...formData, args: e.target.value });
              const temp = actionData;
              console.log(temp);
              temp.arguments[3] = e.target.value;
              setActionData(temp);
            }}
            label="(OPTIONAL) Payload Command"
          />

          {/* Select if the payload uploaded has substeps */}
          <FormControlLabel
            control={
              <Switch
                checked={actionData.arguments.includes("-substeps")}
                onChange={(e) => {
                  const updatedActionData = {
                    ...actionData,
                    arguments: [...actionData.arguments],
                  };

                  if (!updatedActionData.arguments.includes("-substeps")) {
                    updatedActionData.arguments.push("-substeps");
                  } else {
                    const argumentIndex =
                      updatedActionData.arguments.indexOf("-substeps");
                    updatedActionData.arguments =
                      updatedActionData.arguments.filter(
                        (_, index) =>
                          index !== argumentIndex && index !== argumentIndex + 1
                      );
                  }

                  setActionData(updatedActionData);
                }}
              />
            }
            label={"Payload File reports substeps"}
          />

          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};
