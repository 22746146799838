import { React, useContext, useState } from "react";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { Provider } from "./Provider/Provider";
import { useAppSelector } from "../../../Redux/app/hooks";
import { MEHeader } from "./MEHeader";
import { ExpectedAlerts } from "./ExpectedAlerts";
import { useParams } from "react-router-dom";
import { OtherAlerts } from "./OtherAlerts";
import { Stats } from "./Stats";
import { EndpointsTable } from "./EndpointsTable";

const MassExecutionOverview = () => {
  const { id } = useParams();
  const customer = useAppSelector((state) => state.customer);

  return (
    <Provider id={id}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <MEHeader />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",

          }}
          spacing={2}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            spacing={2}
          >
            <Stats />
            <EndpointsTable />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
            }}
            spacing={2}
          >
            <ExpectedAlerts />
            <OtherAlerts />
          </Stack>
        </Stack>
      </Stack>
    </Provider>
  );
};

export default MassExecutionOverview;
