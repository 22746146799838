import { useContext, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Button,
} from "@mui/material";
import { MEOverviewContext } from "./Provider/Context";
import { COLORS } from "../../../Styles/colors";
import { DataGridWithStyles } from "../../../Components";

export const ExpectedAlerts = () => {
  const { massExecution, setExpectedAlert } = useContext(MEOverviewContext);
  const expectedAlerts = massExecution.expected_alerts || [];
  const num_endpoints = massExecution.endpoints?.length;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedExpectedAlert, setSelectedExpectedAlert] = useState(
    expectedAlerts[0] || null
  );
  const [endpointsMissingAlert, setEndpointsMissingAlert] = useState([]);

  useEffect(() => {
    if (selectedExpectedAlert) {
      const findings_found =
        massExecution.findings_found[selectedExpectedAlert.alert_name];
      const endpointIds = findings_found?.endpoints || [];

      const missingEndpoints = massExecution.endpoints.filter(
        (endpoint) => !endpointIds.includes(endpoint.id)
      );
      setEndpointsMissingAlert(missingEndpoints);
    }
  }, [selectedExpectedAlert]);
  const percentage = (num, total) => {
    return ((num / total) * 100).toFixed(2);
  };
  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant="h4">Expected Alerts</Typography>
          {/* <List component="nav" aria-label="expected alerts">
              {expectedAlerts.map((expectedAlert, index) => {
                return (
                  <ListItemButton
                    key={index}
                    selected={selectedIndex === index}
                    onClick={() => {
                      setSelectedIndex(index);
                      setSelectedExpectedAlert(expectedAlert);
                    }}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: COLORS.primary.light,
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6">
                          {expectedAlert.alert_name}
                        </Typography>
                      }
                    />
                    <ListItemText
                      primary={
                        <Typography variant="h6">
                          {percentage(
                            massExecution.findings_found[
                              expectedAlert.alert_name
                            ]?.count || 0,
                            massExecution.passed_count +
                              massExecution.failed_count
                          )}
                          %
                        </Typography>
                      }
                    />
                  </ListItemButton>
                );
              })}
            </List> */}
          <DataGridWithStyles
            columns={[
              { field: "alert_name", headerName: "Alert", flex: 1 },
              {
                field: "count",
                headerName: "Found Percentage",
                flex: 1,
                renderCell: (params) => (
                  <Typography>
                    {percentage(
                      massExecution.findings_found[params.row.alert_name]
                        ?.count || 0,
                      massExecution.passed_count + massExecution.failed_count
                    )}
                    %
                  </Typography>
                ),
              },
              {
                field: "actions",
                headerName: "Actions",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Typography>
                      <Button
                        variant="contained"
                        onClick={() => {
                          // Remove from expected
                          setExpectedAlert(params.row.alert_name);
                        }}
                        sx={{
                          backgroundColor: COLORS.primary.main,
                        }}
                      >
                        <Typography>Remove from Expected</Typography>
                      </Button>
                    </Typography>
                  );
                },
              },
            ]}
            rows={expectedAlerts.map((alert, idx) => ({
              id: idx,
              alert_name: alert.alert_name,
              count:
                massExecution.findings_found[alert.alert_name]?.count || 0,
            }))}
            pageSize={10}
            autoHeight
            onRowClick={(row) => {
              setSelectedIndex(row.rowIndex);
              setSelectedExpectedAlert(row.row);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {endpointsMissingAlert.length > 0 ? (
            <Paper elevation={3} sx={{ width: "100%", p: 3 }}>
              <Typography>Endpoints where the Alert did not fire</Typography>
              <List>
                {endpointsMissingAlert.map((endpoint, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => {
                      // TODO: navigate to the alert campaign for this endpoint
                      console.log(endpoint);
                    }}
                  >
                    <ListItemText primary={endpoint.name} />
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          ) : (
            <Paper
              sx={{ visibility: "hidden", width: "100%", marginTop: 3, p: 3 }}
            >
              <Typography>
                All Endpoints triggered the expected alerts
              </Typography>
              <List>
                <ListItemButton>
                  <ListItemText primary="N/A" />
                </ListItemButton>
              </List>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
