import { useContext, useEffect, useState } from "react";
import { MEOverviewContext } from "./Provider/Context";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { COLORS } from "../../../Styles/colors";

export const Stats = () => {
  const { massExecution, mttd, mttr } = useContext(MEOverviewContext);
  const [avgMTTD, setAvgMTTD] = useState(0);

  const formatTime = (seconds) => {
    let hours = 0;
    let hoursString = "";
    let minutes = Math.floor(seconds / 60);
    // if more than 60 min. convert to hr
    if (minutes / 60 >= 1) {
      hours = Math.floor(minutes / 60);
      minutes -= hours * 60;
      hoursString = `${hours}hr${hours > 1 ? "s " : " "}`;
    }
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours > 0 ? hoursString : ""}${minutes}min ${remainingSeconds}s`;
  };

  //   const avgMTTD = () => {
  //     const total = 0;
  //     if (!mttd) return total;
  //     mttd.forEach((alert) => {
  //       total += alert.total_time;
  //     });
  //     return total / mttd.length;
  //   };

  useEffect(() => {
    if (mttd) {
      let total_time = 0;
      let total_alerts = 0;
      mttd.forEach((alert) => {
        total_time += alert.total_time;
        total_alerts += alert.total_findings;
      });
      setAvgMTTD(total_time / total_alerts);
    }
  }, [mttd]);

  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
        <Box>
          <Typography variant="h6">Pass/Fail Rate</Typography>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PieChart
              series={[
                {
                  arcLabel: (item) => `${item.percent}%`,
                  highlightScope: { faded: "global", highlighted: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                  data: [
                    {
                      id: 0,
                      value: massExecution.passed_count,
                      label: "Passed",
                      percent: massExecution.score,
                    },
                    {
                      id: 1,
                      value: massExecution.failed_count,
                      label: "Failed",
                      percent: (100 - massExecution.score).toFixed(2),
                    },
                  ],
                },
              ]}
              height={150}
              colors={[COLORS.success.light, COLORS.error.light]}
            />
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
        <Stack spacing={2} alignItems="center" height="100%">
          <Box>
            <Typography flex={1} variant={"h5"}>
              Mean Time To Detect
            </Typography>
          </Box>
          <Box flex={1} alignContent="center">
            {!Number.isNaN(avgMTTD) ? (
              <Typography
                sx={{
                  padding: 1,
                  borderRadius: 2,
                  backgroundColor: "lightgray",
                }}
                fontSize={32}
              >
                {formatTime(avgMTTD)}
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
        <Stack spacing={2} alignItems="center" height="100%">
          <Box>
            <Typography flex={1} variant={"h5"}>
              Mean Time To Remediate
            </Typography>
          </Box>
          <Box flex={1} alignContent="center">
            {!Number.isNaN(mttr) ? (
              <Typography
                sx={{
                  padding: 1,
                  borderRadius: 2,
                  backgroundColor: "lightgray",
                }}
                fontSize={32}
              >
                {formatTime(mttr)}
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Stack>
      </Paper>
    </Stack>
  );
};
