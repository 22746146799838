import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { MEOverviewContext } from "./Provider/Context";
import { COLORS } from "../../../Styles/colors";
import { DataGridWithStyles } from "../../../Components";
import { useHttpRequest } from "../../../Hooks";
import { useAppSelector } from "../../../Redux/app/hooks";

export const OtherAlerts = () => {
  const customer = useAppSelector((state) => state.customer);
  const { massExecution, setExpectedAlert } = useContext(MEOverviewContext);

  const [findings, setFindings] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const setAsExpected = (idx) => {
    // when you set Expected Alert here, the request to flip is called. It is in the Context Provider (addRemoveExpectedAlert)
    setExpectedAlert(findings[idx]);
  };

  useEffect(() => {
    if (massExecution.findings_found) {
      const expectedAlerts = massExecution.expected_alerts || [];
      const findings_found = massExecution.findings_found;
      const otherAlerts = Object.keys(findings_found).filter(
        (alert) =>
          !expectedAlerts.map((alert) => alert.alert_name).includes(alert)
      );
      setFindings(otherAlerts);
    }
  }, [massExecution.findings_found]);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={9} md={9}>
          <Typography variant="h4">Other Alerts</Typography>
          {/* <TableContainer component={Paper}>
              <Table aria-label="expected alerts">
                <TableHead>
                  <TableRow>
                    <TableCell>Alert</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {findings &&
                    findings.map((otherAlert, index) => (
                      <TableRow
                        key={index}
                        selected={selectedIndex === index}
                        onClick={() => handleRowClick(index)}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: COLORS.primary.light,
                          },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell>{otherAlert}</TableCell>
                        <TableCell align="right">
                          {selectedIndex === index && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent row click
                                setAsExpected(index);
                              }}
                            >
                              Set As Expected
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          <DataGridWithStyles
            name="other-alerts"
            autoHeight
            paper
            columns={[
              {
                field: "alert",
                headerName: "Alert",
                flex: 1,
              },
              {
                field: "action",
                headerName: "Action",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Button
                      variant="contained"
                      onClick={() => setAsExpected(params.id)}
                      sx={{
                        backgroundColor: COLORS.primary.main,
                      }}
                    >
                      Set As Expected
                    </Button>
                  );
                },
              },
            ]}
            rows={findings.map((alert, idx) => ({
              alert,
              id: idx,
              action: "",
            }))}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
