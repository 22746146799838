import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { handleFileInputChange } from "../functions";
import { useAppSelector } from "../../../../Redux/app/hooks";

const UploadFileModal = ({ setChangesMade, data, setData, simOS }) => {
  const user = useAppSelector((state) => state.user);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedArchitectures, setSelectedArchitectures] = useState([]);
  const [alias, setAlias] = useState("");
  const [file, setFile] = useState(null);
  const [arch, setArch] = useState(null);

  const supportedArchitectures = [
    ["amd64", "Windows 64-bit", "windows"],
    ["386", "Windows 32-bit (x86)", "windows"],
  ];

  const handleArchChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedArchitectures(value);
  };

  const handleUploadFile = (e, archName) => {
    const f = e.target.files[0];
    const originalFileName = f.name;
    const originalFileExtension = originalFileName.split(".").pop(); // Get the extension from the original file name
  
    const reader = new FileReader();
    reader.onload = () => {
      let content = reader.result.split(",")[1]; // b64 part
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const sizeKB = (byteArray.length / 1024).toFixed(2);
  
      // Append archName before the original file extension
      const modifiedFileName = alias
        ? `${alias}.${originalFileExtension}`
        : `${originalFileName.split(".")[0]}.${originalFileExtension}`;
  
      setData((prevData) => ({
        ...prevData,
        input_files: [
          ...prevData.input_files,
          {
            name: modifiedFileName,
            content: byteArray,
            size: sizeKB,
            uploaded_by: user.user.email,
            uploaded_date: new Date().toLocaleString(),
            alias: alias,
            arch: archName,
          },
        ],
      }));
    };
    reader.readAsDataURL(f);
  };
  
  const steps = ["Select Payload Type", "Upload Files"];

  const handleNext = () => {
    if (activeStep === 1) {
      if (selectedOption === "embed") {
        handleFileInputChange(file, setChangesMade, setData, user);
        setFile(null);
      } else {
        handleUploadFile(file, arch);
        setAlias("");
        setFile(null);
        setArch(null);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setSelectedOption("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    handleNext();
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box sx={{ padding: 1 }}>
          <Typography variant="h5">Select Payload Type</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="payload-type"
              name="payload-type"
              value={selectedOption}
              onChange={(e) => handleOptionSelect(e.target.value)}
            >
              <FormControlLabel
                value="embed"
                control={<Radio />}
                label="Embedded Payload"
              />
              <FormControlLabel
                value="arch"
                control={<Radio />}
                label="Architecture Specific File"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}

      {activeStep === 1 && (
        <>
          {selectedOption === "embed" ? (
            <Box sx={{ padding: 1 }}>
              <Typography variant="h6">Embedded Payload File Upload</Typography>
              <TextField
                type="file"
                multiple
                onChange={(e) => setFile(e)}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Box ml={1}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setData((prevData) => ({
                            ...prevData,
                            input_files: [],
                          }));
                          setChangesMade(true);
                        }}
                        disabled={data.input_files.length === 0}
                      >
                        <FaTimes />
                      </IconButton>
                    </Box>
                  ),
                }}
              />
            </Box>
          ) : selectedOption === "arch" ? (
            <Stack sx={{ px: "1rem" }} spacing={1}>
              <Typography variant="h6">Architecture Specific Files</Typography>
              <Typography>
                Files uploaded here will have their architecture appended to
                them. In the simulation steps, they can be referenced as
                whatever alias is set.
              </Typography>
              <TextField
                required
                value={alias}
                onChange={(e) => {
                  setAlias(e.target.value);
                }}
                type="text"
                label="Alias"
                variant="outlined"
              />
              <FormControl required>
                <InputLabel>Architectures Supported</InputLabel>
                <Select
                  value={selectedArchitectures}
                  onChange={(e) => {
                    handleArchChange(e);
                  }}
                  multiple
                  renderValue={(selected) => {
                    return selected.map((x) => x[1]).join(",");
                  }}
                >
                  {supportedArchitectures
                    .filter((arch) => arch[2] === simOS)
                    .map((arch) => {
                      return (
                        <MenuItem key={arch[0]} value={arch}>
                          {arch[1]}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <Stack>
                {selectedArchitectures.map((arch) => {
                  return (
                    <Stack direction={"row"} spacing={3} key={arch[0]}>
                      <Typography>{arch[1]} File:</Typography>
                      <TextField
                        type="file"
                        onChange={(e) => {
                          setFile(e);
                          setArch(arch[0]);
                        }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <Box ml={1}>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setData((prevData) => ({
                                    ...prevData,
                                    input_files: [],
                                  }));
                                }}
                                disabled={data.input_files.length === 0}
                              >
                                <FaTimes />
                              </IconButton>
                            </Box>
                          ),
                        }}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          ) : null}
        </>
      )}

      <Box>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={file === null}
        >
          {activeStep === 1 ? "Upload" : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default UploadFileModal;
