import React, { ReactNode, useEffect, useState } from "react";
import { MEOverviewContext } from "./Context";
import useGetRequest from "../../../../Helpers/httpRequests/useGetRequest";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { useHttpRequest } from "../../../../Hooks";

export const Provider = ({ children, id }) => {
  const [massExecution, setMassExecution] = useState({});
  // mean time to remediate in seconds
  const [mttr, setMttr] = useState(-1);

  // mean time to detect in seconds
  const [mttd, setMttd] = useState([]);

  const [expectedAlert, setExpectedAlert] = useState(null);

  const customer = useAppSelector((state) => state.customer);

  const { response: data, fetch: refresh } = useHttpRequest({
    path: `/api/v2/mass-executions/${id}`,
    method: "GET",
  });

  const { response: mttrDataResponse } = useHttpRequest({
    method: "GET",
    path: `/react/api/${customer.uuid}/alertvalidation/overview/mttr`,
    params: {
      massExecutionId: id,
    },
  });

  const { response: mttdDataResponse } = useHttpRequest({
    method: "GET",
    path: `/react/api/${customer.uuid}/alertvalidation/overview/mttd`,
    params: {
      massExecutionId: id,
    },
  });

  const { fetch: addRemoveExpectedAlert, response: expectedAlertResponse } =
    useHttpRequest({
      path: `/api/v2/mass-executions/${massExecution.id}/expected-alerts`,
      method: "POST",
      data: {
        expected_alert: expectedAlert,
      },
    });

  useEffect(() => {
    if (data) {
      setMassExecution(data);
    }
  }, [data]);

  useEffect(() => {
    if (expectedAlertResponse) {
      console.log(expectedAlertResponse);
      refresh();
    }
  }, [expectedAlertResponse]);

  useEffect(() => {
    if (mttrDataResponse) {
      setMttr(mttrDataResponse.mttr);
    }
  }, [mttrDataResponse]);

  useEffect(() => {
    if (mttdDataResponse) {
      setMttd(mttdDataResponse.mttd);
      setExpectedAlert(null);
    }
  }, [mttdDataResponse]);

  useEffect(() => {
    addRemoveExpectedAlert();
  }, [expectedAlert]);

  return massExecution ? (
    <MEOverviewContext.Provider
      value={{
        massExecution,
        mttr,
        mttd,
        expectedAlert,
        setExpectedAlert,
      }}
    >
      {children}
    </MEOverviewContext.Provider>
  ) : null;
};
